import React from 'react';
import TextCard from '~/components/molecules/TextCard';
import ArticlePreview from '~/components/molecules/ArticlePreview';
import CustomCursor from '~/components/atoms/CustomCursor';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper.scss';

export default function ResourcesSlider({ className, title, button, posts=[]}) {
  SwiperCore.use([Scrollbar]);

  const textCard = {
    "heading": {
      "text": title,
      "type": "h2"
    },
    "buttons": [
      {
        "button": {
          "button": {
            "button": button[0]
          },
          "type": "textLink"
        }
      }
    ]
  }

  return (
    <section className={`overflow-hidden my-20 md:my-36 ${className}`}>
      <div className="container">
        <header className="mb-16 md:mb-20">
          <TextCard
          className="grid grid-cols-12 w-full items-end"
          headingClassName="!mb-0"
          innerClassName="col-span-12 md:col-span-9 text-center md:text-left"
          buttonContainerClassName="col-span-12 md:col-span-2 md:col-start-11 text-center md:text-right"
          {...textCard}
          />
        </header>
        <div className="">
          <CustomCursor content="Drag" className="h-full w-full">
            <Swiper
              className="w-full flex flex-col"
              slidesPerView={`auto`}
              grabCursor="1"
              freeMode="1"
              scrollbar={{ draggable: true }}
              style={{ overflow: `visible` }}
            >
              {posts.slice(0, 20).map((item, i) => (
                <SwiperSlide
                  className="max-w-[80%] mr-12 md:max-w-sm last:mr-0"
                  key={`hpswiper${i}`}
                  style={{ height: 'auto' }}
                >
                  <div>
                    <ArticlePreview {...item} className="" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </CustomCursor>
        </div>
      </div>
    </section>
  );
}
