import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import ArticlePreview from '~/components/molecules/ArticlePreview'

export default function FeaturedResources({className, heading, button, type = 'grid', category}) {
  const buttons = [
    {
      "button": {
        "button": {
          "button": button
        },
        "type": "textLink"
      }
    }
  ];

  return (
    <section className={`my-20 md:my-36 ${className}`}>
      <div className="container">
        <header className="mb-16 md:mb-20">
          <TextCard
            heading={{text: heading || category.name, type:'h2' }}
            buttons={buttons}
            className="grid grid-cols-12 w-full items-end"
            headingClassName="!mb-0"
            innerClassName="col-span-12 md:col-span-9 text-center md:text-left"
            buttonContainerClassName="col-span-12 md:col-span-2 md:col-start-11 text-center md:text-right"
          />
        </header>

        {type === '3-col' ? (
          <div className="grid grid-cols-12 gap-y-16 md:gap-12">
            {category.posts.nodes.slice(0,3).map(item => (
              <ArticlePreview key={`preview${item.id}`} {...item} className="col-span-12 md:col-span-4" />
            ))}
          </div>
        ) : (type === 'grid' && (
          <div className="grid grid-cols-12 gap-y-10 md:gap-10">
            <div className="col-span-12 md:col-span-6 md:order-1">
              <ArticlePreview {...category.posts.nodes[0]} />
            </div>
            <div className="col-span-12 md:col-span-6 grid grid-cols-12 gap-y-10 gap-x-4 md:gap-10">
              {category.posts.nodes.slice(1,5).map((item, i) => (
                <ArticlePreview key={item.id} {...item} className="col-span-6" />
              ))}
            </div>
          </div>
          )
        )}
      </div>
    </section>
  )
}
