import { graphql } from 'gatsby';
import React, {useEffect} from 'react';
import SEO from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import HeroResources from '~/components/organisms/HeroResources';
import FeaturedResources from '~/components/organisms/FeaturedResources';
import Resources from '~/components/organisms/Resources';
import ResourcesSlider from '~/components/organisms/ResourcesSlider';
import Newsletter from '~/components/organisms/Newsletter';
import globalState from '~/store/index';

const ResourcesPage = (props) => {
  const { pageContext:{ resourceType }, data: { wpPage: { title, resourcesListing: { resourcesListing } }, allWpPost, wp } } = props;

  const [type, setType] = globalState('resourceType');
  useEffect(()=>{
    setType(resourceType);
  },[resourceType])


  return (
    <Layout showResourcesNav headerBgWhite>
      <SEO
        title={`${title} | City Relay`}
        postSchema={JSON.parse(wp.seo.contentTypes.post.schema.raw)}
      />
      <HeroResources heading={resourcesListing.heading} post={allWpPost.nodes[0]} />

      {resourcesListing.categories.map(({ type, header, category }, i) => {
        return type !== 'slider' ? (
          <FeaturedResources key={type+i} heading={header.heading} button={header.button} type={type} category={category} />
        ): (
          <ResourcesSlider
            title={header.heading || category.name}
            button={[header.button]}
            category={category}
            posts={category.posts.nodes}
          />
        )
      })}
      <Newsletter heading="Sign up to our newsletter!" content="Subscribe to City Relay and be the first to hear about new content, upcoming events, our podcast, the latest industry news and much more."/>
      <Resources resourceType={resourceType} />
    </Layout>
  );
};

export default ResourcesPage;


export const pageQuery = graphql`
  query ResourcesListingQuery($id: String!, $resourceType: String!) {

    allWpPost(
      limit: 1,
      sort: {fields: date, order: DESC},
      filter: {
        resourceTypes: { nodes: { elemMatch: { slug: { eq: $resourceType }}}},
        categories: { nodes: { elemMatch: { name: { nin: [ "Uncategorized", "Uncategorised"] }}}},
      }
    ) {
      nodes {
        ...PostPreview
      }
    }

    wpPage(id: { eq: $id }) {
      title
      resourcesListing {
        resourcesListing {
          heading
          categories {
            type
            header {
              heading
              button {
                ...Button
              }
            }
            category {
              name
              posts {
                nodes {
                  ...PostPreview
                }
              }
            }
          }
        }
      }
    }

    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
  }
`;
