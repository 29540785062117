import React from 'react';
import TextCard from '~/components/molecules/TextCard';
import Image from '~/components/atoms/Image';
import { StaticImage } from 'gatsby-plugin-image';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function HeroResources({ heading, post }) {

  const textCard = {
    "heading": {
      "type": "h4",
      "text": post.title
    },
    "subheading": post.categories.nodes[0].name,
    "subheadingClassName": "!text-blue",
    "buttons": [
      {
        "button": {
          "button": {
            "button": {
              "title": "Keep Reading",
              "url": post.uri,
              "target": ""
            }
          },
          "type": "textLink"
        }
      }
    ]
  }

  return (
    <section className="mb-20 md:mb-36 py-48 pb-20  bg-sand">
      <div className="container relative">
        <FadeInWhenVisible className=" grid grid-cols-12 mb-20">
          <h1 className="text-h1-small col-span-12 lg:col-span-6">{heading}</h1>
        </FadeInWhenVisible>
        <div className="grid grid-cols-12 items-center relative">
          <div className="col-span-12 md:col-span-7">
            <FadeInWhenVisible className="md:-mr-40 ">
              <div className="aspect-w-10 aspect-h-7 ">
                <Image loading={'eager'} data={post.featuredImage.node} className="!absolute w-full h-full" />
              </div>
            </FadeInWhenVisible>
          </div>
          <FadeInWhenVisible className="bg-white col-span-12 md:col-span-5 relative z-20">
            <TextCard
              className="text-center py-20 lg:py-32 px-20 mt-auto"
              {...textCard}
            />
          </FadeInWhenVisible>
        </div>
        <StaticImage loading={'eager'} src={'../../assets/images/resources-illustration.png'} className=" lg:w-80 !hidden lg:!block !absolute top-8 md:right-20 lg:right-40 xl:right-56 z-10" objectFit="contain" objectPosition="center" alt="" />
      </div>
    </section>
  );
}
